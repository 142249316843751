import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { InitializeEffects } from './+state/initialize.effects';
import { INITIALIZE_FEATURE_KEY, initializeReducer } from './+state/initialize.reducer';
import { RouterStoreService } from './router-store.service';

export function provideInitializeStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: INITIALIZE_FEATURE_KEY, reducer: initializeReducer }),
    provideEffects(InitializeEffects),
    RouterStoreService,
  ];
}
