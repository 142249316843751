import { APP_INITIALIZER } from '@angular/core';
import { TtFeatureFlagsConfig } from './tt-feature-flags.config';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

export function provideTtFeatureFlags(options: TtFeatureFlagsConfig) {
  options = { ...new TtFeatureFlagsConfig(), ...options };

  return [
    { provide: TtFeatureFlagsConfig, useValue: options },
    { provide: TtFeatureFlagsService },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {
        /* init Service*/
      },
      deps: [TtFeatureFlagsService],
      multi: true,
    },
  ];
}
