import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import { firstValueFrom } from 'rxjs';
import { AuthConfig } from '../auth.config';
import { CHECK_USER_ROLES } from '../auth.provider';
import { InvalidRoleError } from '../shared/errors/invalid-role.error';
import { InvalidTokenError } from '../shared/errors/invalid-token.error';
import { ProfileCallFailsError } from '../shared/errors/profile-call-fails.error';
import { OAuthService } from '../shared/oauth/oauth.service';
import { UserService } from '../shared/user/user.service';

/**
 * This method is executed at the start of the Guard's authentication.
 * It saves the original URL into the local storage, so we can redirect the user
 * back to that URL after authentication.
 */
function saveOriginUrl(
  state: RouterStateSnapshot,
  storage: Storage,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: AuthConfig<any, any>,
): Promise<void> {
  if (!state.url.match(`.*(login|authenticate|${config.homeUrl}).*`)) {
    return storage.create().then(() => storage.set('originUrl', state.url));
  }
  return Promise.resolve();
}

/**
 * This method is executed when an InvalidRoleError was thrown. It presents an
 * Ionic toast, explaining this to the users.
 *
 * This method can be overridden to create your own warning for the user.
 */
function handleInvalidRoleError(): Promise<void> {
  const toastController = inject(ToastController);
  if (toastController) {
    return toastController
      .create({
        message: 'Je hebt niet de juiste rechten om in te loggen.',
        duration: 5000,
        color: 'danger',
        position: 'bottom',
      })
      .then((toast) => toast.present());
  }
  return Promise.resolve();
}

/**
 * This method handles the standard error which can occur in this auth guard.
 * It returns a Promise containing a boolean stating that the error is handled (or not).
 * The Auth Guard contains a fallback for when the Error isn't handled.
 *
 * This method can be overloaded when extending. If so start by calling the base
 * method. After which you can implement you own code to handle Errors which
 * aren't handled by this base class.
 *
 * This base class handle the following ErrorTypes.
 * - {@see InvalidRoleError}
 * - {@see InvalidTokenError}
 * - {@see ProfileCallFailsError}
 *
 * @Example
 * ```typescript
 *
 * @Injectable({
 *   providedIn: 'root',
 * })
 * export class OAuthLoginGuard extends OAuthGuard implements CanActivate {
 *
 *   protected async handleError(error: Error): Promise<boolean> {
 *     let response: boolean = await super.handleError(error);
 *     if (response) {
 *       return Promise.resolve(response);
 *     }
 *
 *     switch (true) {
 *       case error instanceof CustomProjectError:
 *         console.error('Handling Custom Project Error!');
 *         return this.oauthService.redirectToLoginPage();
 *     }
 *
 *     return Promise.resolve(false);
 *   }
 * }
 * ```
 */
async function handleError(
  error: Error,
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  userService: UserService<any>,
  oauthService: OAuthService,
): Promise<boolean | UrlTree> {
  switch (true) {
    case error instanceof InvalidTokenError:
      return oauthService.getLoginUrlTree();
    case error instanceof ProfileCallFailsError:
      await Promise.all([userService.logout(), oauthService.clearAuthenticationTokens()]);
      return oauthService.getLoginUrlTree();
    case error instanceof InvalidRoleError:
      await Promise.all([
        handleInvalidRoleError(),
        userService.logout(),
        oauthService.clearAuthenticationTokens(),
      ]);
      return oauthService.getLoginUrlTree();
  }
  return Promise.resolve(false);
}

export async function oauthAuthenticationGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> {
  const storage = inject(Storage);
  const userService = inject(UserService);
  const config = inject(AuthConfig);
  const checkUserRolesFn = inject(CHECK_USER_ROLES, { optional: true });
  const oauthService = inject(OAuthService);
  try {
    const authenticated: boolean = await userService.isAuthenticated();

    if (!authenticated) {
      await saveOriginUrl(state, storage, config);
      return oauthService.getLoginUrlTree();
    }

    if (checkUserRolesFn) {
      const promise: void | Promise<void> = checkUserRolesFn(
        await firstValueFrom(userService.currentUser()),
        route,
      );

      if (promise instanceof Promise) {
        await promise;
      }
    }

    return authenticated;
  } catch (error) {
    await saveOriginUrl(state, storage, config);
    const isHandled: boolean | UrlTree = await handleError(
      error as Error,
      userService,
      oauthService,
    );
    if (isHandled instanceof UrlTree) {
      return isHandled;
    }

    // If the handleError function didn't handle the error it will return false
    // as a fallback we're going to redirect the user to the login page.
    return oauthService.getLoginUrlTree();
  }
}
