import { Injector } from '@angular/core';
import { PermissionUserInterface } from '../contracts/permission-user.interface';

interface StandardEnum<T> {
  [id: string]: T | string;
  [nu: number]: string;
}

/**
 * This class is the abstract base for each permission class. To use the permission
 * service you should create your own permission class implementing the {@see validate}
 * method which returns either true or false representing if the user has permission
 * for the given subject.
 *
 * @example
 * ```typescript
 * enum SlotPermissionSubjects {
 *   CREATE = 'CREATE',
 *   READ = 'READ',
 *   UPDATE = 'UPDATE',
 * }
 *
 * export class SlotPermission extends BasePermission {
 *   public readonly subjects: typeof SlotPermissionSubjects = SlotPermissionSubjects;
 *
 *   private bookingPeriodService: BookingPeriodService;
 *
 *   constructor(protected injector: Injector) {
 *     super(injector);
 *     this.bookingPeriodService = injector.get(BookingPeriodService);
 *   }
 *
 *   public validate(subject: string, slot: Slot): boolean {
 *     switch (subject) {
 *       case SlotPermissionSubjects.READ:
 *       case SlotPermissionSubjects.CREATE:
 *         return this.onlyAdmin();
 *       case SlotPermissionSubjects.UPDATE:
 *         return this.update(slot);
 *       default:
 *         throw new InsufficientPermissionsError();
 *     }
 *   }
 *
 *   private onlyAdmin(): boolean {
 *     // Only admins are allowed.
 *     return this.is('admin');
 *   }
 *
 *   private update(slot: Slot): boolean {
 *     return !slot.assignment.canUpdate;
 *   }
 * }
 * ```
 */
export abstract class BasePermission<U = PermissionUserInterface> {
  /**
   * The list of available subjects for the class.
   *
   * Autocomplete only works for the {@see PermissionService} method and not
   * for the {@see IsGrantedDirective}.
   *
   * Also, the subjects list doesn't have to be enum. A {@see Record} object should work as well.
   */
  public readonly subjects!: StandardEnum<unknown>;

  /**
   * A Set containing all the roles the current user has, including all roles
   * given through the {@see TtPermissionConfig.roleHierarchy}.
   */
  public roles!: Set<string>;

  /**
   * The currently logged-in user.
   */
  public user!: U;

  /**
   * The Mapped version of the {@see TtPermissionConfig.roleHierarchy}.
   */
  public roleHierarchy!: Map<string, string[]>;

  constructor(protected injector: Injector) {}

  /**
   * The method is executed on each {@see PermissionService.isGranted} method.
   *
   * This method should return either true or false representing if the user has
   * permission for the given subject.
   *
   * In addition, it's also possible to throw an {@see InsufficientPermissionsError}
   * which is caught by the {@see PermissionService.isGranted} method and converts it
   * into false.
   *
   * @param subject The subject for which the user asks permission.
   * @param args the left over properties of
   */
  public abstract validate(subject: string, ...args: unknown[]): boolean;

  public is(role: string): boolean {
    return this.roles.has(role);
  }

  public isOneOf(roles: string[]): boolean {
    for (let role of roles) {
      if (this.is(role)) {
        return true;
      }
    }
    return false;
  }
}
