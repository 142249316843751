import { Injectable, inject } from '@angular/core';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UnleashClient, IMutableContext as UnleashMutableContext } from 'unleash-proxy-client';
import { TtFeatureFlagsConfig } from './tt-feature-flags.config';

@Injectable()
export class TtFeatureFlagsService {
  private readonly sentryErrorHandler = inject(SentryErrorHandler);

  private readonly config = inject(TtFeatureFlagsConfig);

  private unleashClient: UnleashClient;

  private isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private customFlags$: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject<
    Record<string, boolean>
  >({});

  private onUpdate$: Subject<void> = new Subject<void>();

  constructor() {
    this.unleashClient = this.createUnleashInstance();

    this.unleashClient.on('initialized', async () => {
      this.isInitialized$.next(true);
      try {
        await this.start();
      } catch (error) {
        return this.sentryErrorHandler.captureError(error);
      }
    });
    this.unleashClient.on('updated', () => {
      this.onUpdate$.next(undefined);
    });
    this.unleashClient.on('error', (error: Error) => {
      return this.sentryErrorHandler.captureError(error);
    });
  }

  public get isInitialized(): Observable<boolean> {
    return this.isInitialized$.asObservable();
  }

  public get onUpdate(): Observable<unknown> {
    return this.onUpdate$.asObservable();
  }

  public start(): Promise<void> {
    return this.unleashClient.start();
  }

  public stop(): void {
    this.unleashClient.stop();
  }

  public setFlag(name: string, value: boolean): void {
    this.customFlags$.next({
      ...this.customFlags$.value,
      [name]: value,
    });
    this.onUpdate$.next();
  }

  public removeFlag(name: string): void {
    const flags = this.customFlags$.value;
    delete flags[name];
    this.customFlags$.next(flags);
    this.onUpdate$.next();
  }

  public updateContext(contextObj: UnleashMutableContext): Promise<void> {
    return this.unleashClient.updateContext(contextObj).then(() => this.onUpdate$.next());
  }

  public isEnabled(feature: string): boolean {
    const custom: boolean = feature in this.customFlags$.value;
    if (custom) {
      return this.customFlags$.value[feature];
    }
    return this.unleashClient.isEnabled(feature);
  }

  private createUnleashInstance(): UnleashClient {
    return new UnleashClient({
      appName: this.config.appName,
      environment: this.config.environment,
      url: this.config.url,
      // the parameter is Required by the Sdk which is needed with the unleash-proxy-server
      // Some project have an open endpoint as replacement which doesn't use this.
      // therefor we give a random uuid as placeholder.
      clientKey: this.config.clientKey ?? '054a622e-e344-4144-be85-c6d43f055840',
      disableRefresh: this.config.disableRefresh,
      refreshInterval: this.config.refreshInterval,
      metricsInterval: this.config.metricsInterval,
      disableMetrics: this.config.disableMetrics,
      storageProvider: this.config.storageProvider,
      context: this.config.context,
      bootstrap: this.config.bootstrap,
      bootstrapOverride: this.config.bootstrapOverride,
    });
  }
}
