import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  IonButton,
  IonCheckbox,
  IonLabel,
  IonNote,
  IonSpinner,
  ModalController,
} from '@ionic/angular/standalone';
import { SlotDetailedContract } from '@scheduler-frontend/assignment-contracts';
import { BusinessServicesStoreService } from '@scheduler-frontend/data-access-business-services';
import { RoleContract, RoleDetailedContract } from '@scheduler-frontend/data-access-roles';
import { timeRangeValidator } from '@techniek-team/common';
import { TtIonSelectSearchControlComponent } from '@techniek-team/components/ion-select-search';
import { TtModalComponent, TtModalTitleComponent } from '@techniek-team/components/modal';
import { TtTimeInputControlComponent } from '@techniek-team/components/time-input';
import { jsonLdSelectId, JsonLdSelectIdPipe } from '@techniek-team/tt-ngrx';
import { formatISO, parseISO } from 'date-fns';
import { NgxControlError } from 'ngxtension/control-error';
import { EditSlotStoreService } from '../edit-slot-store.service';
import { SlotDetailsComponent } from './slot-details/slot-details.component';

@Component({
  selector: 'app-edit-slot-modal',
  standalone: true,
  imports: [
    TtModalComponent,
    SlotDetailsComponent,
    TtTimeInputControlComponent,
    NgxControlError,
    ReactiveFormsModule,
    JsonLdSelectIdPipe,
    TtIonSelectSearchControlComponent,
    IonLabel,
    IonNote,
    IonCheckbox,
    IonButton,
    IonSpinner,
    TtModalTitleComponent,
  ],
  templateUrl: './edit-slot-modal.component.html',
  styleUrl: './edit-slot-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSlotModalComponent {
  protected readonly editSlotStoreService = inject(EditSlotStoreService);

  protected readonly modalController = inject(ModalController);

  protected readonly businessServicesStoreService = inject(BusinessServicesStoreService);

  protected readonly editSlotForm = new FormGroup({
    role: new FormControl<RoleContract | null>(null, [Validators.required]),
    timeRange: new FormGroup(
      {
        startTime: new FormControl<Date | null>(null, [Validators.required]),
        endTime: new FormControl<Date | null>(null, [Validators.required]),
      },
      [timeRangeValidator({})],
    ),
    performSkillCheck: new FormControl(true, { nonNullable: true }),
  });

  protected readonly setEditSlotForm = effect(() => {
    const slot = this.editSlotStoreService.slot();
    const role = this.editSlotStoreService.selectedSlotRole();

    this.editSlotForm.reset({
      role: (role as RoleContract) ?? null,
      timeRange: {
        startTime: slot ? parseISO(slot?.timePeriod.start) : null,
        endTime: slot ? parseISO(slot?.timePeriod.end) : null,
      },
      performSkillCheck: (slot as SlotDetailedContract)?.performSkillCheck ?? null,
    });
    this.editSlotForm.markAsPristine();
    this.editSlotForm.markAsUntouched();
  });

  protected roleDisplayValue = (item: RoleContract | RoleDetailedContract) => {
    const businessService =
      this.businessServicesStoreService.businessServiceEntities()[
        jsonLdSelectId(item.businessService)
      ];
    return `${item.abbreviation} - ${item.name} (${businessService?.businessEntity?.name})`;
  };

  protected save(): void {
    const value = this.editSlotForm.value;
    this.editSlotStoreService.submitEditSlot({
      performSkillCheck: value.performSkillCheck as boolean,
      timeRange: {
        start: formatISO(value.timeRange?.startTime as Date),
        end: formatISO(value.timeRange?.endTime as Date),
        inclusiveStart: true,
        inclusiveEnd: false,
      },
      role: value.role as RoleContract,
    });
  }
}
