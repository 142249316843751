import { EnvironmentProviders, Provider } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { provideSlotsStore } from '@scheduler-frontend/data-access-slots';
import { EditSlotEffects } from './+state/edit-slot.effects';
import { createEditSlotReducer, EDIT_SLOT_FEATURE_KEY } from './+state/edit-slot.reducer';
import { OpenEditSlotEffects } from './+state/open-edit-slot.effects';
import { EditSlotStoreService } from './edit-slot-store.service';

export function provideEditSlotStore(): Provider | EnvironmentProviders[] {
  return [
    provideSlotsStore(),
    provideState({ name: EDIT_SLOT_FEATURE_KEY, reducer: createEditSlotReducer }),
    provideEffects(OpenEditSlotEffects, EditSlotEffects),
    EditSlotStoreService,
    ModalController,
  ];
}
