import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  bootstrapApplication,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import 'hammerjs';
import 'reflect-metadata';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NoPreloading, PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { Drivers } from '@ionic/storage';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { OpenAssignmentModalEffect } from '@scheduler-frontend/assignment-modal';
import { provideCreateSlotsStore } from '@scheduler-frontend/create-slot-modal';
import { provideAssignmentsStore } from '@scheduler-frontend/data-access-assignment';
import { provideBusinessEntitiesStore } from '@scheduler-frontend/data-access-business-entities';
import { provideBusinessServicesStore } from '@scheduler-frontend/data-access-business-services';
import { provideInitializeStore } from '@scheduler-frontend/data-access-initialize';
import { provideLocationsStore } from '@scheduler-frontend/data-access-locations';
import { provideProductTypesStore } from '@scheduler-frontend/data-access-product-types';
import { provideRegionsStore } from '@scheduler-frontend/data-access-regions';
import { provideRolesStore } from '@scheduler-frontend/data-access-roles';
import { provideSchedulingStore } from '@scheduler-frontend/data-access-scheduling';
import { provideSubjectsStore } from '@scheduler-frontend/data-access-subjects';
import { provideUsersStore } from '@scheduler-frontend/data-access-users';
import { provideEditSlotStore } from '@scheduler-frontend/edit-slot-modal';
import { environment } from '@scheduler-frontend/environments';
import { checkUserRoles } from '@scheduler-frontend/permissions';
import { jsonLdInterceptor, provideIonicStorage } from '@techniek-team/common';
import { provideTtFeatureFlags } from '@techniek-team/feature-flags';
import {
  authInterceptor,
  LyceoUser,
  provideTtAuth,
  UserService,
  withUserRoles,
} from '@techniek-team/oauth';
import { PermissionService, provideTtPermissions } from '@techniek-team/permissions';
import { provideSentry, SentryEnvironment } from '@techniek-team/sentry-web';
import { setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';
import { MainComponent } from './main.component';
import { ROUTES } from './main.routing';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeNl, 'nl');
setDefaultOptions({
  locale: nl,
  weekStartsOn: 1,
});

bootstrapApplication(MainComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: MAT_DATE_LOCALE, useValue: nl },
    provideAnimations(),
    provideHttpClient(withInterceptors([jsonLdInterceptor, authInterceptor])),
    provideRouter(
      ROUTES,
      withPreloading(environment.production ? PreloadAllModules : NoPreloading),
    ),
    provideIonicAngular({
      useSetInputAPI: true,
    }),
    provideIonicStorage({
      name: '__scheduler-frontend',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    provideDateFnsAdapter(),
    provideSentry({
      environment: environment.environment,
      dsn: 'https://31bab22356a0415494b67b7b5a5493ef@errors.techniek-team.nl//31',
      release: environment.release,
      userService: UserService,
      autoSessionTracking: true,
    }),
    provideTtPermissions({
      roleHierarchy: {
        ROLE_SKOLEO_TT_PLANNER_ADMIN: [
          'admin',
          'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
          'ROLE_SKOLEO_TT_PLANNER_CLUSTER_MANAGEMENT',
          'ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM',
        ],
        ROLE_SKOLEO_TT_PLANNER_CLUSTER_MANAGEMENT: 'clusterManager',
        ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT: 'locationManager',
        ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM: 'schedulingTeam',
      },
      userService: UserService,
    }),
    provideTtAuth(
      {
        clientId: environment.ssoConfig.clientId,
        ssoBaseUrl: environment.ssoConfig.ssoBaseUrl,
        profileBaseUrl: environment.ssoConfig.profileBaseUrl,
        baseUrl: environment.ssoConfig.baseUrl,
        redirectUri: environment.ssoConfig.redirectUri,
        loginUrl: '/login',
        homeUrl: '/home',
        whitelist: ['amazon'],
        model: LyceoUser,
      },
      withUserRoles(checkUserRoles, [PermissionService]),
    ),
    provideTtFeatureFlags({
      url: environment.gitlabFeatureFlags.url,
      appName: 'skoleo-scheduler-front-end',
    }),
    provideStore(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: false,
          strictActionTypeUniqueness: false,
        },
      },
    ),
    provideRouterStore(),
    provideEffects(OpenAssignmentModalEffect),
    !environment.production
      ? provideStoreDevtools({
          maxAge: 100, // Retains last 100 states
          logOnly: environment.environment !== SentryEnvironment.LOCAL, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          trace: true,
          connectInZone: true,
        })
      : [],
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    importProvidersFrom(HammerModule),
    { provide: LOCALE_ID, useValue: 'nl' },
    provideInitializeStore(),
    provideUsersStore(),
    provideProductTypesStore(),
    provideLocationsStore(),
    provideRolesStore(),
    provideRegionsStore(),
    provideSubjectsStore(),
    provideBusinessEntitiesStore(),
    provideBusinessServicesStore(),
    provideAssignmentsStore(),
    provideCreateSlotsStore(),
    provideEditSlotStore(),
    provideSchedulingStore(),
  ],
})
  //eslint-disable-next-line no-console
  .catch((err) => console.log(err));
