import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { isAfter, isBefore } from 'date-fns';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthConfig } from '../auth.config';
import { OAuthService } from '../shared/oauth/oauth.service';

function requestWithToken(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
  storage: Storage,
  oAuthService: OAuthService,
): Observable<HttpEvent<unknown>> {
  return from(
    Promise.all([
      storage.create().then(() => storage.get('accessToken')),
      storage.create().then(() => storage.get('accessTokenExpiresIn')),
    ]),
  ).pipe(
    mergeMap(([accessToken, accessTokenExpiresIn]) => {
      if (isBefore(accessTokenExpiresIn, new Date())) {
        return from(oAuthService.refreshAccessToken()).pipe(
          mergeMap(() => from(storage.get('accessToken'))),
        );
      }
      return of(accessToken);
    }),
    map((accessToken) => {
      return !accessToken
        ? request.clone()
        : request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + accessToken),
          });
    }),
    mergeMap((newRequest) => next(newRequest)),
  );
}

export function authInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
  const storage = inject(Storage);
  const config = inject(AuthConfig);
  const oAuthService = inject(OAuthService);
  // Don't add the access token to the /token request
  if (request.url.includes('/token') || request.method === 'JSONP') {
    return next(request.clone());
  }

  // Check if URL is on the whitelist: don't add the access token to the request
  if (
    (config.whitelist ?? []).length &&
    new RegExp((config.whitelist ?? []).join('|')).test(request.url)
  ) {
    return next(request.clone());
  }

  return requestWithToken(request, next, storage, oAuthService).pipe(
    catchError((error: unknown) => {
      // only display errors when there is no 401 error
      if (!(error instanceof HttpErrorResponse) || error.status !== 401) {
        return throwError(() => error);
      }

      // if we receive a 401 refresh the accessToken or if necessary
      // redirect the user to the login page
      return from(oAuthService.refreshAccessToken()).pipe(
        switchMap(() => requestWithToken(request, next, storage, oAuthService)),
      );
    }),
  );
}
