import { DOCUMENT } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Storage } from '@ionic/storage';

/**
 * This component is nothing more than a bootstrap component. Facilitating the routing within the
 * single page application.
 */
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [RouterOutlet],
})
export class MainComponent {
  private readonly storage = inject(Storage);

  private readonly document = inject<Document>(DOCUMENT);

  private readonly route = inject(ActivatedRoute);

  constructor() {
    this.storage.create().catch();
  }
}
