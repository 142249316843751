import { inject, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { assignmentsActions } from '@scheduler-frontend/data-access-assignment';
import { DeclineReasonContract } from '@scheduler-frontend/data-access-decline-reasons';
import { exhaustMap, from, Observable, switchMap } from 'rxjs';
import { AssignmentModalComponent } from './assignment-modal/assignment-modal.component';

@Injectable()
export class OpenAssignmentModalEffect {
  private readonly actions$ = inject(Actions);

  private readonly modalController = inject(ModalController);

  public readonly openAssignmentModal: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignmentsActions.openAssignmentModal),
        exhaustMap(() =>
          from(
            this.modalController.create({
              component: AssignmentModalComponent,
            }),
          ).pipe(
            switchMap((response) => response.present().then(() => response)),
            switchMap((response) => response.onWillDismiss<DeclineReasonContract | undefined>()),
          ),
        ),
      ),
    { dispatch: false },
  );
}
